import React from "react";
import theme from "theme";
import { Theme, Link, Image, Box, Section, Text } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"privacy"} />
		<Helmet>
			<title>
				EULA
			</title>
			<meta name={"description"} content={"A cognitive editor for Java and Kotlin"} />
			<meta property={"og:title"} content={"Cognitive Editor"} />
			<meta property={"og:description"} content={"A cognitive editor for Java and Kotlin\n"} />
		</Helmet>
		<Image
			position="absolute"
			bottom="auto"
			md-width="600px"
			md-top="300px"
			lg-right="-200px"
			lg-top="0px"
			src="https://uploads.quarkly.io/6123949e957019001e284458/images/backgroung-hero.svg?v=2021-09-21T22:07:12.314Z"
			right="0px"
			top="130px"
			z-index="-10"
		/>
		<Section background="linear-gradient(264.47deg, #000848 29.39%, #000848 93.49%)" padding="36px 0 36px 0" quarkly-title="Header">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				empty-border-width="1px"
				width="70%"
				align-items="center"
				sm-width="50%"
				display="flex"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-style="solid"
				empty-border-color="LightGray"
			>
				<Components.QuarklycommunityKitMobileSidePanel md-z-index="9">
					<Override slot="Button Text" md-display="none" />
					<Override slot="Button Icon" md-color="--white" md-font="36px sans-serif" />
					<Override slot="Cross" md-color="--dark" md-font="36px sans-serif" />
					<Override slot="Wrapper" md-z-index="9" />
					<Override
						slot="Content"
						md-justify-content="flex-start"
						background="rgba(255, 255, 255, 0)"
						justify-content="center"
						align-items="center"
						padding="16px 0px 16px 16px"
						md-background="--color-light"
						md-z-index="9"
					/>
					<Override slot="Children" display="flex" align-items="center" justify-content="center" />
					<Box
						justify-content="flex-end"
						md-flex-direction="column"
						md-margin="40px 0px 13px 0px"
						md-font="--headline4"
						display="flex"
						align-items="center"
					>
						<Link
							color="--white"
							margin="0px 20px 0px 20px"
							md-margin="0px 0px 13px 0px"
							md-font="--lead"
							md-color="--indigo"
							href="/index"
							font="normal 600 18px/1.5 --fontFamily-googleInter"
							text-decoration-line="initial"
						>
							Home
						</Link>
						<Link
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							href="/index#features"
							font="--base"
							text-decoration-line="initial"
							hover-opacity="1"
							md-font="--lead"
							md-color="--indigo"
							color="--grey"
							margin="0px 20px 0px 20px"
							opacity="0.8"
						>
							Features
						</Link>
						<Link
							color="--grey"
							margin="0px 20px 0px 20px"
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							md-font="--lead"
							href="/index#public-beta"
							font="--base"
							text-decoration-line="initial"
							opacity="0.8"
							hover-opacity="1"
							md-color="--indigo"
						>
							Public Beta
						</Link>
						<Link
							hover-opacity="1"
							md-font="--lead"
							md-color="--indigo"
							font="--base"
							color="--grey"
							opacity="0.8"
							transition="opacity 0.3s ease 0s"
							md-margin="0px 0px 13px 0px"
							href="#about"
							text-decoration-line="initial"
							margin="0px 8px 0px 20px"
						>
							About
						</Link>
					</Box>
				</Components.QuarklycommunityKitMobileSidePanel>
			</Box>
		</Section>
		<Section background="linear-gradient(264.47deg, #000848 29.39%, #000848 93.49%)" padding="36px 0 80px 0" quarkly-title="HeroBlock">
			<Override
				slot="SectionContent"
				md-z-index="7"
				flex-direction="row"
				position="relative"
				z-index="9"
				md-flex-wrap="wrap"
			/>
			<Box
				empty-border-width="1px"
				align-items="flex-start"
				md-width="100%"
				display="flex"
				flex-direction="column"
				empty-border-color="LightGray"
				width="70%"
				justify-content="center"
				padding="0px 180px 0px 0px"
				lg-padding="0px 90px 0px 0px"
				empty-min-height="64px"
				empty-border-style="solid"
				empty-min-width="64px"
				md-padding="0px 0px 0px 0px"
			>
				<Text
					letter-spacing="1%"
					lg-text-align="left"
					lg-font="normal 700 40px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 28px/1.2 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 16px 0px"
					font="--headline1"
					color="--white"
					sm-font="normal 500 30px/1.2 &quot;Inter&quot;, sans-serif"
				>
					EULA
				</Text>
			</Box>
		</Section>
		<Section>
			<Text>
				<b>Version 1.0, as of February 6th, 2025</b>
			</Text>
			<Text>
				This EULA (End User License Agreement) is concluded between You and the Developer with respect to the Plugin.
			</Text>

			<Text font="--headline4" color="--indigo">1. Definitions</Text>

			<Text>
				"<b>Confirmation</b>" means an email from JetBrains confirming Your rights to use a Paid Plugin and
				containing important information about Your license or Subscription.
			</Text>
			<Text>
				"<b>Developer</b>" means the company monochromata UG (haftungsbeschränkt) Friedrichstraße 95,
				10117 Berlin, Germany registered with the commercial register Amtsgericht Charlottenburg
				under number HRB 267515 B.
			</Text>
			<Text>
				"<b>Documentation</b>" means the latest versions of all online technical documentation available for a
				Plugin at JetBrains Marketplace and any other relevant Plugin documentation provided either by JetBrains
				or the Developer that applies to the Plugin.
			</Text>
			<Text>
				"<b>Fallback Date</b>" means the date falling twelve (12) months prior to the date Your Subscription
				expired.
			</Text>
			<Text>
				"<b>Fallback Version</b>" means the most recent Plugin Version that the Developer made available for
				public purchase prior to the Fallback Date, along with any bug-fix updates for that Plugin Version. The
				Fallback Version does not include any Plugin updates or upgrades other than bug-fix updates that You may
				have used in the period between the Fallback Date and the expiration date of Your Subscription. For more
				information, see FAQ available at https://sales.jetbrains.com/hc/en-gb.
			</Text>
			<Text>
				"<b>Free Plugin</b>" means a Plugin that is offered at no cost.
			</Text>
			<Text>
				"<b>JetBrains</b>" means JetBrains s.r.o., which has its registered office at Na Hřebenech II 1718/8, Prague, 14000, Czech Republic, registered with the Commercial Register kept by the Municipal Court of Prague, Section C, file 86211, ID.Nr.: 265 02 275.
			</Text>
			<Text>
				"<b>JetBrains Affiliate</b>" means the subsidiary and/or any associated companies of JetBrains.
			</Text>
			<Text>
				"<b>JetBrains Marketplace</b>" means any platform operated by JetBrains or a JetBrains Affiliate on which JetBrains or JetBrains Affiliate markets Plugins for JetBrains Products, including the website https://plugins.jetbrains.com and/or any other website or other platform, whether named JetBrains Marketplace, JetBrains Plugins Repository, or otherwise.
			</Text>
			<Text>
				"<b>JetBrains Product</b>" means any software program or service made available by JetBrains.
			</Text>
			<Text>
				"<b>Paid Plugin</b>" means a Plugin that is offered for a fee (can be either a one-time license fee or a recurring subscription fee).
			</Text>
			<Text>
				"<b>Plugin</b>" means any plugin, extension, application, widget, or other software program designed to work with JetBrains Products that the Developer makes available under this Standard EULA.
			</Text>
			<Text>
				"<b>Plugin Users</b>" means users that are able to access and use the Plugin concurrently.
			</Text>
			<Text>
				"<b>Plugin Version</b>" means a release, update, or upgrade of a particular Plugin that is not identified by the Developer as being made for the purpose of fixing software bugs.
			</Text>
			<Text>
				"<b>Subscription</b>" means Your right to use a Paid Plugin during the Subscription Period.
			</Text>
			<Text>
				"<b>Subscription Period</b>" means the Subscription period described in Your Confirmation.
			</Text>
			<Text>
				"<b>You</b>" means an individual or an entity concluding this Standard EULA.
			</Text>

			<Text font="--headline4" color="--indigo">2. Grant of License</Text>
			<Text>
				2.1. License. The Developer hereby grants You a limited, worldwide, non-exclusive, non-transferable
				license to use the Plugin (including any generally available updates and upgrades released during your
				rightful use of the Plugin) as long as the use is in line with Your Confirmation, the Documentation, and
				the limits set out in this Standard EULA. You may:
			</Text>
			<Text>
				a. install the Plugin;
			</Text>
			<Text>
				b. use the Plugin subject to the limits described in Your Confirmation (if any); and
			</Text>
			<Text>
				c. make backup copies of the Plugin for archival purposes.
			</Text>
			<Text>
				2.2. Restrictions. You may not modify, reverse-engineer, decompile, or disassemble the Plugin in whole
				or in part, or create any derivative works from the Plugin, or sublicense any rights to the Plugin,
				unless otherwise expressly authorized in writing by the Developer.
			</Text>
			<Text>
				2.3. Duration of License. The license granted under this Standard EULA can be provided as:
			</Text>
			<Text>
				a. perpetual in case of Free Plugins or Paid Plugins for which You are charged a one-time license fee,
				and
			</Text>
			<Text>
				b. time-limited for the duration of your Subscription Period, in case of Paid Plugins for which You pay
				an annual or monthly Subscription fee.
			</Text>
			<Text>
				For more details about the type of Paid Plugin, see the information in Your Confirmation.
			</Text>

			<Text font="--headline4" color="--indigo">3. Subscription</Text>
			<Text>
				3.1. Subscription Limits. If You purchased a Plugin Subscription, You must use the Paid Plugin in
				accordance with the limits described in Your subscription plan (e.g., You must ensure that the maximum
				number of Plugin Users does not exceed the number of Plugin Users for which You have purchased the
				Subscription).
			</Text>
			<Text>
				3.2. Subscription Period. The Subscription Period can be either annual or monthly.
			</Text>
			<Text>
				3.3. Fallback License. If the Plugin is provided with a Fallback license according to the Confirmation,
				following the expiration of the Subscription Period without a renewal, Your license to use the Plugin as
				stipulated in Section 2 shall continue on a perpetual, royalty-free, non-exclusive, and non-transferable
				basis with respect to the Fallback Version of the Plugin (including all limitations). The granting of
				the Fallback license is expressly contingent upon Your compliance with this EULA, including Your payment
				of the applicable Subscription fees for at least the preceding 12 months in full and without
				interruptions.
			</Text>

			<Text font="--headline4" color="--indigo">4. Intellectual Property</Text>
			<Text>
				The Plugin is protected by copyright and other intellectual property laws and treaties. The Developer or
				its licensors own all title, copyright, and other intellectual property rights to the Plugin.
			</Text>

			<Text font="--headline4" color="--indigo">5. Disclaimer of Warranty</Text>
			<Text>
				ALL PLUGINS ARE PROVIDED TO YOU ON AN "AS-IS" AND "AS AVAILABLE" BASIS WITHOUT WARRANTIES. YOUR USE OF
				PLUGINS IS AT YOUR OWN RISK. THE DEVELOPER PROVIDES NO WARRANTY AS TO THE PLUGIN'S USE OR PERFORMANCE.
				TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE DEVELOPER DISCLAIMS ALL OTHER WARRANTIES AND
				CONDITIONS, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
				MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, WITH REGARD TO THE
				PLUGIN, AND THE PROVISION OF OR FAILURE TO PROVIDE SUPPORT SERVICES. THE DEVELOPER DOES NOT WARRANT THAT
				THE PLUGIN IS ACCURATE, RELIABLE, OR CORRECT; THAT THE PLUGIN MEETS YOUR REQUIREMENTS; THAT THE PLUGIN
				WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION, UNINTERRUPTED, OR SECURE; THAT ANY DEFECTS OR
				ERRORS WILL BE CORRECTED; OR THAT THE PLUGIN IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. EXCEPT AS
				REQUIRED BY APPLICABLE LAWS, ANY UPDATES AND UPGRADES TO THE PLUGIN WILL ONLY BE PROVIDED ON AN “AS
				AVAILABLE” BASIS, I.E., AT THE DEVELOPER’S SOLE DISCRETION.
			</Text>

			<Text font="--headline4" color="--indigo">6. Limitation of Liability</Text>
			<Text>
				IN NO EVENT WILL THE DEVELOPER BE LIABLE FOR ANY DIRECT OR INDIRECT COSTS, LOSSES, OR DAMAGES ASSOCIATED
				WITH THE USE OF THE DEVELOPER'S PLUGIN.
			</Text>
			<Text>
				THE DEVELOPER SHALL NOT BE LIABLE TO YOU FOR ANY LOST PROFITS OR CONSEQUENTIAL DAMAGES, NO MATTER THE
				CAUSE OF SAID PROFITS OR DAMAGES, AND IN NO EVENT WILL THE DEVELOPER'S AGGREGATE LIABILITY ARISING OUT
				OF OR RELATED TO THIS AGREEMENT OR THE USE OF THE PLUGIN EXCEED THE FEES WHICH YOU PAID FOR THE PLUGIN
				VIA JETBRAINS MARKETPLACE IN THE THREE-MONTH PERIOD PRECEDING THE CLAIM. THIS LIMITATION WILL APPLY EVEN
				IF THE DEVELOPER HAS BEEN ADVISED OF THE POSSIBILITY OF THE LIABILITY EXCEEDING THE AMOUNT AND
				NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY. JETBRAINS' LIABILITY IS EXCLUDED
				IN ITS ENTIRETY AS JETBRAINS IS NOT A PARTY TO THE CONTRACTUAL RELATIONSHIP BETWEEN THE DEVELOPER AND
				YOU.
			</Text>
		</Section>
		<Section background="--color-indigo" padding="20px 0 40px 0" md-padding="40px 0 0px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-border-width="1px"
				display="flex"
				md-width="100%"
				md-align-items="center"
				flex-direction="column"
				padding="16px 16px 16px 16px"
				md-justify-content="center"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="25%"
			>
				<Link
					text-decoration-line="initial"
					transition="opacity 0.3s ease 0s"
					hover-opacity="1"
					margin="0px 0px 10px 0px"
					font="--base"
					href="/"
					color="--white"
					opacity=".8"
				>
					Home
				</Link>
				<Link
					opacity=".8"
					text-decoration-line="initial"
					transition="opacity 0.3s ease 0s"
					hover-opacity="1"
					margin="0px 0px 10px 0px"
					font="--base"
					href="https://monochromata.de/doc/"
					color="--white"
				>
					Documentation
				</Link>
				<Link
					opacity=".8"
					text-decoration-line="initial"
					transition="opacity 0.3s ease 0s"
					hover-opacity="1"
					margin="0px 0px 10px 0px"
					font="--base"
					href="https://github.com/monochromata/cognitive-editor/releases"
					color="--white"
				>
					Releases on GitHub
				</Link>
				<Link
					color="--white"
					opacity=".8"
					text-decoration-line="initial"
					transition="opacity 0.3s ease 0s"
					hover-opacity="1"
					margin="0px 0px 10px 0px"
					font="--base"
					href="https://github.com/monochromata/cognitive-editor/issues"
				>
					Issues on GitHub
				</Link>
			</Box>
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				width="25%"
				display="flex"
				md-width="100%"
				md-justify-content="center"
				empty-border-width="1px"
				empty-border-style="solid"
				flex-direction="column"
				padding="16px 16px 16px 16px"
				md-align-items="center"
			>
				<Link
					color="--white"
					opacity=".8"
					text-decoration-line="initial"
					transition="opacity 0.3s ease 0s"
					hover-opacity="1"
					margin="0px 0px 10px 0px"
					font="--base"
					href="/privacy"
				>
					Privacy policy
				</Link>
				<Link
					color="--white"
					opacity=".8"
					text-decoration-line="initial"
					transition="opacity 0.3s ease 0s"
					hover-opacity="1"
					margin="0px 0px 10px 0px"
					font="--base"
					href="/eula"
				>
					EULA
				</Link>
				<Link
					color="--white"
					opacity=".8"
					text-decoration-line="initial"
					transition="opacity 0.3s ease 0s"
					hover-opacity="1"
					margin="0px 0px 10px 0px"
					font="--base"
					href="/impressum"
				>
					Impressum
				</Link>
			</Box>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6123949e957019001e284456"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});